<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="addShow = true">添加群聊</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #faceUrl="{ row }">
        <el-image
          style="width: 60px; border-radius: 6px; height: 60px"
          :src="row.faceUrl"
        ></el-image>
      </template>

      <template #handle="{ row }">
        <el-button type="success" size="small" @click="openUpdate(row)"
          >修改</el-button
        >
        <el-button type="primary" size="small" @click="openMember(row)"
          >成员管理</el-button
        >
        <el-button type="danger" size="small" @click="delGroup(row)"
          >解散群聊</el-button
        >
      </template>
    </auto-table>

    <!-- 添加员工 -->
    <el-dialog title="添加员工" :visible.sync="addShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="群头像">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="getFaceUrl"
            :url="addForm.faceUrl"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="群名称" required>
          <el-input v-model="addForm.group_name"></el-input>
        </el-form-item>
        <el-form-item label="运动类目">
          <el-select v-model="category" clearable multiple>
            <el-option
              v-for="(item, index) in categories_child_list"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="员工职位" prop="type">
          <el-radio v-model="addForm.type" label="staff">员工</el-radio>
          <el-radio v-model="addForm.type" label="staffManager">经理</el-radio>
          <el-radio v-model="addForm.type" label="staffKeeper">管家</el-radio>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="createGroup">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改-->
    <el-dialog title="修改" :visible.sync="updateShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="群头像">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="(v) => (updateInfo.faceUrl = v)"
            :url="updateInfo.faceUrl"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="群名称" required>
          <el-input v-model="updateInfo.group_name"></el-input>
        </el-form-item>
        <el-form-item label="运动类目">
          <el-select v-model="category" clearable multiple>
            <el-option
              v-for="(item, index) in categories_child_list"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="updateGroup">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="成员" :visible.sync="memberShow" width="50%">
      <auto-table
        :DataList="memberList"
        :option="memberOption"
        :total="memberTotal"
        @changePage="getMember"
      >
        <template #avatar="{ row }">
          <el-image
            style="width: 60px; border-radius: 6px; height: 60px"
            :src="row.avatar"
          ></el-image>
        </template>

        <template #handle="{ row }">
          <el-button type="danger" size="small" @click="delGroupMember(row)"
            >移除成员</el-button
          >
        </template>
      </auto-table>
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="添加用户">
          <user-select :userid.sync="userid" ref="user"></user-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="addMember">邀请进群</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="memberShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  props: {
    mechanism_id: {
      default: 0,
    },
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "头像", value: "faceUrl", type: "custom" },
        { name: "群名", value: "group_name" },
        { name: "运动类目", value: "category" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      addShow: false,
      addForm: {
        name: "",
        phone: "",
      },

      updateShow: false,
      updateInfo: "",

      manager: JSON.parse(localStorage.getItem("managerInfo")),

      categories_child_list: [],
      category: "",

      memberShow: false,
      changeGroup: {},
      memberList: [],
      memberOption: [
        { name: "头像", value: "avatar", type: "custom" },
        { name: "群名", value: "nick" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      memberTotal: 0,
      userid: "",
    };
  },
  watch: {
    mechanism_id: {
      handler(nv) {
        if (nv) {
          this.getList(1);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getCateList();
  },
  methods: {
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },

    openMember(row) {
      this.memberShow = true;
      this.changeGroup = row;
      this.getMember(1);
    },
    delGroupMember(row) {
      this.$confirm("确定移除--" + row.nick).then(() => {
        this.$post("/user/tim/delGroupMember", {
          memberList: [row.user_id],
          group_id: this.changeGroup.group_id,
        }).then((res) => {
          this.getMember();
        });
      });
    },
    addMember() {
      this.$post("/user/tim/addGroupMember", {
        memberList: [this.userid],
        group_id: this.changeGroup.group_id,
      }).then((res) => {
        this.getMember();
        this.$refs.user.clear()
      });
    },
    getMember(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/tim/groupMemberList",
        params: {
          offset: (page - 1) * 10,
          limit: 10,
          group_id: this.changeGroup.group_id,
        },
      }).then((res) => {
        this.memberTotal = res.data.data.MemberNum;
        let rows = res.data.data.MemberList || [];
        this.memberList = rows.map((t) => {
          return {
            user_id: t.To_Account,
            ...this.getProfileItem(t.ProfileItem),
          };
        });
      });
    },
    getProfileItem(list) {
      let map = {};
      list.forEach((item) => {
        switch (item.Tag) {
          case "Tag_Profile_IM_Nick":
            map.nick = item.Value;
            break;
          case "Tag_Profile_IM_Image":
            map.avatar = item.Value;
            break;
          default:
            break;
        }
      });
      return map;
    },

    openUpdate(row) {
      this.updateInfo = Object.assign({}, row);
      this.category = this.updateInfo.category.split(",");
      this.updateShow = true;
    },

    delGroup(row) {
      this.$confirm("确定解散群聊").then(() => {
        this.$axios({
          url: "/user/group/deleteGroup",
          params: {
            group_id: row.group_id,
          },
        }).then((res) => {
          this.$message("解散成功");
          this.getList(1);
        });
      });
    },
    updateGroup() {
      this.$axios({
        url: "/user/group/update",
        method: "post",
        data: {
          id: this.updateInfo.id,
          group_name: this.updateInfo.group_name,
          category: this.category.join(","),
          faceUrl: this.updateInfo.faceUrl || null,
        },
      })
        .then((res) => {
          this.updateShow = false;
          this.getList(1);
          this.$message("成功");
          this.category = [];
        })
        .catch((err) => {
          this.$message.error("失败");
        });
    },
    createGroup() {
      this.$axios({
        url: "/user/group/createGroup",
        method: "post",
        data: {
          group_name: this.addForm.group_name,
          category: this.category.join(","),
          mechanism_id: this.mechanism_id,
          faceUrl: this.addForm.faceUrl || null,
        },
      })
        .then((res) => {
          this.addShow = false;
          this.getList(1);
          this.$message("添加成功");
          this.category = [];
        })
        .catch((err) => {
          this.$message.error("添加失败");
        });
    },
    getFaceUrl(v) {
      this.addForm.faceUrl = v;
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/group/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          mechanism_id: this.mechanism_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.color {
  color: rgb(238, 145, 57);
}
</style>