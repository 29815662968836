<template>
  <div>
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-descriptions title="机构详情" v-if="orgInfo.id">
        <template slot="extra">
          <el-button
            type="warning"
            size="small"
            @click="changeRecommand(false)"
            v-if="orgInfo.is_recommend == true"
          >
            取消优选</el-button
          >
          <el-button
            type="success"
            size="small"
            @click="changeRecommand(true)"
            v-if="orgInfo.is_recommend == false"
          >
            推荐优选</el-button
          >
        </template>
        <el-descriptions-item label="机构名称">
          {{ orgInfo.mechanism_name }}
          <el-tag size="small" v-if="orgInfo.is_recommend">优选</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="机构地址">
          {{ orgInfo.mechanism_addr }}
        </el-descriptions-item>
        <el-descriptions-item label="负责人">
          {{ orgInfo.contacts }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话">
          {{ orgInfo.contact_telephone }}
        </el-descriptions-item>
        <el-descriptions-item label="经营类目">
          {{ orgInfo.categories_child }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <span v-if="orgInfo.status == 1" style="color: #23c3c4"
            ><span> 待审核</span>
          </span>
          <span v-else-if="orgInfo.status == 2" style="color: #32cd32"
            >已通过</span
          >
          <span v-else-if="orgInfo.status == 3" style="color: #ff6262"
            >已拒绝</span
          >
          <span v-else-if="orgInfo.status == 4" style="color: #ff6262"
            >注销</span
          >
          <span v-else-if="orgInfo.status == 5" style="color: #ff6262"
            >冻结</span
          >
          <el-button @click="pass(2)" type="success" v-if="orgInfo.status != 2"
            >通过审核</el-button
          >
          <el-button @click="pass(3)" type="danger" v-if="orgInfo.status == 1"
            >审核拒绝</el-button
          >
        </el-descriptions-item>

        <el-descriptions-item label="机构logo">
          <el-image
            style="width: 150px; height: 150px"
            :src="orgInfo.mechanism_logo"
            fit="cover"
            :preview-src-list="[orgInfo.mechanism_logo]"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="营业执照">
          <el-image
            v-for="(pic, index) in orgInfo.support_means.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[orgInfo.support_means.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>
         <el-descriptions-item label="运营资质">
          <el-image
            v-for="(pic, index) in orgInfo.qualifications_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[orgInfo.qualifications_pic.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="机构门面">
          <el-image
            v-for="(pic, index) in orgInfo.facade_view.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[orgInfo.facade_view.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="介绍图片">
          <el-image
            v-for="(pic, index) in orgInfo.introduction_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[orgInfo.introduction_pic.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="container">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="课程" name="first">
          <el-table :data="couList" v-loading="is_loading">
            <el-table-column label="课程id" prop="id"> </el-table-column>
            <el-table-column label="课程标题" prop="title"> </el-table-column>
            <el-table-column
              label="课程总节数"
              prop="course_num"
            ></el-table-column>
            <el-table-column
              label="课程购买数"
              prop="pay_num"
            ></el-table-column>
            <el-table-column
              label="课程单节价格"
              prop="price_per_session"
            ></el-table-column>
            <el-table-column label="课程总价" prop="amout">
            </el-table-column>
            <!-- todo -->
            <el-table-column
              label="上架时间"
              prop="create_time"
              width="200px"
            ></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: red"
                  >审核拒绝</span
                >
                <span v-if="scope.row.status == 2" style="color: green"
                  >已通过</span
                >
                <!-- <span v-if="scope.row.status == 3" style="color: red">审核拒绝</span> -->
                <span v-if="scope.row.status == 4" style="color: orange"
                  >待审核</span
                >
                <span v-if="scope.row.status == 5" style="color: red"
                  >下架</span
                >
                <span v-if="scope.row.status == 6">冻结</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-button
                    size="small"
                    type="success"
                    @click="openClassShow(scope.row)"
                    >课程开班</el-button
                  >
                  <el-button size="small" @click="toCouDetails(scope.row.id)"
                    >查看详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changeCouListCurrentPage"
            :current-page="couListCurrentPage"
            :total="couTotal"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="场馆" v-if="orgInfo.type != '机构商户'" name="second">
          <el-table :data="placeList" v-loading="is_loading">
            <el-table-column label="场馆标题" prop="name"> </el-table-column>
            <el-table-column label="场馆类目" prop="categories_child">
            </el-table-column>
            <el-table-column
              label="所属机构"
              prop="mechanismName"
            ></el-table-column>
            <el-table-column label="区域" prop="district"></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0" style="color: green"
                  >正常</span
                >
                <span v-if="scope.row.status == 1" style="color: red"
                  >已冻结</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div style="display: flex">
                  <!-- <el-button size="small">下架</el-button> -->
                  <el-button
                    size="small"
                    type="primary"
                    @click="toPlaceDetails(scope.row.id)"
                    >查看详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changePlaceListCurrentPage"
            :current-page="placeListCurrentPage"
            :total="placeTotal"
            background
          ></el-pagination>
        </el-tab-pane>
        <!-- 教练列表 -->
        <el-tab-pane v-if="orgInfo.type != '机构商户'" label="教练" name="third" style="padding: 1rem 1rem 0">
          <el-table :data="teaList" v-loading="is_loading">
            <el-table-column label="头像">
              <template slot-scope="scope">
                <el-image
                  style="height: 5rem"
                  :src="scope.row.head_portrait"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="real_name">
              <template slot="header">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="部分教练信息需要等待后台审核通过后生效"
                  placement="bottom"
                >
                  <div>
                    姓名
                    <i class="el-icon-warning-outline"></i>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="联系方式" prop="phone"></el-table-column>
            <el-table-column label="性别" prop="sex">
              <template slot-scope="{ row }">
                <span>{{ row.sex == 1 ? "男" : "女" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="教龄" prop="teaching_age">
            </el-table-column>
            <el-table-column
              label="教学类别"
              prop="teaching_categories"
            ></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0" style="color: orange"
                  >信息待提交</span
                >
                <span v-if="scope.row.status == 1" style="color: orange"
                  >正在审核</span
                >
                <span v-if="scope.row.status == 2" style="color: green"
                  >审核通过</span
                >
                <span v-if="scope.row.status == 3" style="color: red"
                  >审核拒绝</span
                >
                <span v-if="scope.row.status == 5" style="color: red"
                  >冻结</span
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" prop="" width="280">
              <template slot-scope="{ row }">
                <el-button
                  size="small"
                  type="success"
                  @click="toCoachDetails(row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="teaListCurrentPage"
            :total="teaTotal"
            @current-change="changeTeaListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <!-- 订单列表 -->
        <el-tab-pane label="订单" name="four" style="padding: 1rem 1rem 0">
          <el-table
            :data="payList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
            v-loading="is_loading"
          >
            <el-table-column align="center" label="订单号" width="150">
              <template v-slot="{ row }">
                <p class="textell">{{ row.flowing_no }}</p>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              label="商品名称"
              :formatter="OrderName"
            >
            </el-table-column>
            <el-table-column
              prop="course_num"
              align="center"
              label="课节数"
            ></el-table-column>
            <el-table-column align="center" label="用户昵称">
              <template v-slot="{ row }">
                <link-to
                  :to="{ name: 'userDetails', params: { id: row.user_id } }"
                >
                  {{
                    row.map.userinfo ? row.map.userinfo.nick_name : ""
                  }}</link-to
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              align="center"
              label="付款金额"
            ></el-table-column>
            <el-table-column
              prop="pay_type"
              align="center"
              label="付款方式"
              :formatter="Paytype"
            ></el-table-column>
            <el-table-column
              prop="source"
              align="center"
              label="订单渠道"
              :formatter="Paysource"
            ></el-table-column>
            <el-table-column
              prop="finished_time"
              align="center"
              label="下单时间"
              width="200px"
            ></el-table-column>
            <el-table-column prop="city" align="center" label="订单类型">
              <template slot-scope="{ row }">
                <div>{{ OrderType(row.rcharge_type) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 2" style="color: #67c23a"
                  >已付款</span
                >
                <span v-if="scope.row.status == 4" style="color: #f56c6c"
                  >退款</span
                >
                <span v-if="scope.row.status == 1" style="color: #6f7174"
                  >待付款</span
                >
                <span v-if="scope.row.status == 0" style="color: #6f7174"
                  >待付款</span
                >
                <span v-if="scope.row.status == 8" style="color: #6f7174"
                  >已取消</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot="{ row }">
                <link-to :to="{ name: 'orderDetails', params: { id: row.id } }">
                  <p>详情</p>
                </link-to>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="payListCurrentPage"
            :total="payTotal"
            @current-change="changePayListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="用户" name="five" style="padding: 1rem 1rem 0">
          <el-table :data="stuList" v-loading="is_loading">
            <el-table-column
              label="学生姓名"
              prop="map.userinfo.nick_name"
            ></el-table-column>
            <el-table-column
              label="学生手机号"
              prop="map.userinfo.mobile"
            ></el-table-column>
            <el-table-column label="性别" prop="sex">
              <template slot-scope="scope">
                {{ scope.row.sex == 1 ? "男" : "女" }}
              </template>
            </el-table-column>
            <el-table-column
              label="年龄"
              prop="map.userinfo.age"
            ></el-table-column>
            <el-table-column
              label="总课时数"
              prop="map.masterSetPriceEntity.course_num"
            ></el-table-column>
            <el-table-column
              label="报名课程"
              prop="map.masterSetPriceEntity.title"
            ></el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <el-button @click="toStuDetails(scope.row.user_id)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="stuListCurrentPage"
            :total="stuTotal"
            @current-change="changeStuListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane name="clas" label="机构班级">
          <classList :mechanism_id="id" ref="clas"></classList>
        </el-tab-pane>
        <el-tab-pane name="consultant" label="顾问计划">
          <plan-list :user_id="id" :type="2"></plan-list>
        </el-tab-pane>
        <el-tab-pane name="adjust" label="调整金额">
          <adjust-list :mechanism_id="id" ref="adjust"></adjust-list>
        </el-tab-pane>
        <el-tab-pane v-if="orgInfo.is_community" name="build" label="楼栋设置">
          <setBuilding :use_id="id"></setBuilding>
        </el-tab-pane>
         <el-tab-pane v-if="orgInfo.type != '机构商户'" name="group" label="群聊管理">
          <groupList :mechanism_id="id" ></groupList>
        </el-tab-pane>
         <el-tab-pane v-if="orgInfo.type != '机构商户'" name="admin" label="负责人">
          <adminMec :mechanism_id="id" ></adminMec>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog title="课程开班" :visible.sync="classShow" width="30%">
      <el-form>
        <el-form-item label="开班课程">
          {{ curCourse.title }}
        </el-form-item>
        <el-form-item label="班级名称">
          <el-input v-model="form.title" placeholder="班级名称"></el-input>
        </el-form-item>
        <el-form-item label="班级人数">
          <el-input
            v-model="form.student_count_max"
            placeholder="班级人数"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="classShow = false">取 消</el-button>
        <el-button type="primary" @click="createClass">开 班</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getorderType } from "@/utils/map/orderType.js";
import planList from "../DetailsInfo/components/planList.vue";
import classList from "./components/classList.vue";
import adjustList from "./components/adjustList.vue";
import setBuilding from './components/setBuilding.vue';
import groupList from './components/groupList.vue';
import adminMec from './components/adminMec.vue';

export default {
  components: {
    planList,
    classList,
    adjustList,
    setBuilding,groupList,
    adminMec
  },
  data() {
    return {
      dd: "",
      nav: { firstNav: "机构管理中心", secondNav: "机构详情" },
      orgInfo: {
        environment_pic: "",
        facade_view: "",
        support_means: "",
        mechanism_logo: "",
      },
      is_loading: true,
      activeName: "first",

      couList: [],
      couTotal: 0,
      couListCurrentPage: 1,

      placeList: [],
      placeTotal: 0,
      placeListCurrentPage: 1,

      teaList: [],
      teaTotal: 0,
      teaListCurrentPage: 1,

      payList: [],
      payTotal: 0,
      payListCurrentPage: 1,

      stuList: [],
      stuTotal: 0,
      stuListCurrentPage: 1,

      spyList: [],
      spyTotal: 0,
      spyListCurrentPage: 1,
      // unFinishedCourse:[],
      id: "",

      classShow: false,
      curCourse: {},
      form: {},
    };
  },
  updated() {
    this.dd = window.screen.width < 768 ? "width:100%" : "width:25%";
  },
  methods: {
    openClassShow(row) {
      this.curCourse = row;
      this.$set(this.form, "title", row.title);
      this.$set(this.form, "student_count_max", row.connect_peoplenum);
      this.classShow = true;
    },
    createClass() {
      this.$axios({
        url: "/user/mechanismClasses/insert",
        method: "post",
        data: {
          mechanism_id: this.curCourse.mechanism_id,
          course_name: this.curCourse.title,
          name: this.form.title,
          type: "机构班级",
          master_set_price_id: this.curCourse.id,
          student_count_max: this.form.student_count_max,
        },
      }).then((res) => {
        this.$message("开班成功,可前往班级管理设置学员");
        this.classShow = false;
      });
    },
    OrderType(type) {
      return getorderType(type).label;
    },
    OrderName(row) {
      if (row.title) {
        return row.title;
      } else if (row.map.masterSetPriceEntity) {
        return row.map.masterSetPriceEntity.title;
      } else {
        return this.OrderType(row.rcharge_type);
      }
    },
    Paytype(row, column) {
      let type = row.pay_type;
      if (type == "ali") {
        return "支付宝";
      } else if (type == "wx") {
        return "微信";
      } else if (type == "point") {
        return "权益点";
      } else if (type == "dy") {
        return "抖音";
      } else {
        return type;
      }
    },
    Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else {
        return source;
      }
    },
    splitImgs(urlString) {
      return urlString ? urlString.split(",") : [];
    },
    getOrgPayList() {
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: this.payListCurrentPage,
            finished: true,
            mechanism_id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          console.log(res);
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    getOrgDetails() {
      let url = "/user/mastermechanism/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          // console.log(res);
          this.orgInfo = res.data.data;
        });
    },
    getOrgCouList() {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "mechanism_offline",
            pageSize: 10,
            currentPage: this.couListCurrentPage,
            mechanism_id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          // console.log(res);
          this.couList = res.data.data.rows;
          this.couTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgPlaceList() {
      let url = "/user/mechanism/venue/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.placeListCurrentPage,
            mechanism_id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          this.placeList = res.data.data.rows;
          this.placeTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgTeaList() {
      let url = "/user/coachMechanismConnected/list";
      this.$axios
        .get(url, {
          params: {
            mechanism_parent_id: this.$route.query.mecID,
            pageSize: 10,
            currentPage: this.teaListCurrentPage,
          },
        })
        .then((res) => {
          this.teaList = res.data.data.rows;
          this.teaTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgStuList() {
      let url = "/user/userStudyCard/querySchoolStudentList";
      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.$route.query.mecID,
            pageSize: 10,
            currentPage: this.stuListCurrentPage,
            type: "mechanism_offline",
          },
        })
        .then((res) => {
          this.stuList = res.data.data.rows;
          this.stuTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },

    unshelve(id) {
      let url = "/user/masterInfo/update";
      this.$axios
        .post(url, {
          id: id,
          status: 1,
        })
        .then((res) => {
          this.is_loading = true;
          this.getOrgTeaList();
          this.$message({ type: "success", message: "下架成功!" });
        });
    },
    pass(s) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          status: s,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    changeRecommand(status) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          is_recommend: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    toCouDetails(id) {
      this.$router.push({
        name: "courseDetails",
        params: { id: id },
      });
    },
    toPlaceDetails(id) {
      this.$router.push({
        name: "venueDetails",
        params: { id: id },
      });
    },
    toCoachDetails(row) {
      this.$router.push({ name: "coachDetails", query: { id: row.id } });
    },
    toStuDetails(id) {
      this.$router.push({
        name: "userDetails",
        params: { id },
      });
    },
    changeCouListCurrentPage(v) {
      this.is_loading = true;
      this.couListCurrentPage = v;
      this.getOrgCouList();
    },
    changePlaceListCurrentPage(v) {
      this.is_loading = true;
      this.placeListCurrentPage = v;
      this.getOrgPlaceList();
    },
    changeTeaListCurrentPage(v) {
      this.is_loading = true;
      this.teaListCurrentPage = v;
      this.getOrgTeaList();
    },
    changePayListCurrentPage(v) {
      this.is_loading = true;
      this.payListCurrentPage = v;
      this.getOrgPayList();
    },
    changeStuListCurrentPage(v) {
      this.is_loading = true;
      this.stuListCurrentPage = v;
      this.getOrgStuList();
    },
    tabClick(tab, event) {
      this.is_loading = true;
      if (tab.index == 0) {
        this.couListCurrentPage = 1;
        this.getOrgCouList();
      } else if (tab.index == 1) {
        this.placeListCurrentPage = 1;
        this.getOrgPlaceList();
      } else if (tab.index == 2) {
        this.teaListCurrentPage = 1;
        this.getOrgTeaList();
      } else if (tab.index == 3) {
        this.payListCurrentPage = 1;
        this.getOrgPayList();
      } else if (tab.index == 4) {
        this.stuListCurrentPage = 1;
        this.getOrgStuList();
      }
      if (tab.name == "clas") {
        this.$refs.clas.getList(1);
      }
      if (tab.name == "adjust") {
        this.$refs.adjust.getList(1);
      }
    },
  },
  mounted() {
    this.id = this.$route.query.mecID;
    this.getOrgDetails();
    this.getOrgCouList();
  },
};
</script>

<style>
.aaaa {
  display: flex;
  flex-direction: column;
}

/* .el-row.orgInfo {
  height: 15rem;
  padding-left: 1rem;
}
.el-row.orgInfo .el-col {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  justify-content: space-around;
}
.el-row.orgInfo .el-col div {
  color: #434343;
} */
.mecInfo div > span {
  margin-right: 1rem;
}

.mecInfo div .el-button {
  margin-left: 1rem;
}

.third-title {
  font-size: 1.6rem;
  padding-left: 1rem;
}

.el-image {
  overflow: unset !important;
}

.error-image {
  height: 10rem;
  width: 10rem;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mecInfo {
  line-height: 2.5rem;
  font-size: 1.5rem;
}
</style>
